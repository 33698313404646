// WRITE ARTICLE
export const editTasks = {
  order: 3,
  name: "Edit tasks",
  keywords: "multiple priority due date list link",
  subcategory: "Tasks",
  markdown: `# Edit tasks

  ## Inline editing

  ## Detailed editing

  ## Datapoint linking

  ## Batch editing`,
};
